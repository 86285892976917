<app-user-status [showBackButton]="true"></app-user-status>
<app-banner-cuenta></app-banner-cuenta>
<app-boton-agregar></app-boton-agregar>

<div class="container-fluid contenedor-94">
    <div class="row d-flex justify-content-evenly align-items-center">

        <div class="col-11 mb-5 aparece">
            <div class="mb-3 d-flex flex-column justify-content-center align-items-center">
                <p class="m-0">Inversión inicial</p>
                <h3>{{ inversionInicial | currency:'USD':'symbol':'1.0-0' }} <span class="fs-4 fw-medium">USD</span></h3>
            </div>
            
            <div class="d-flex flex-column justify-content-center align-items-center">
                <p class="text-secondary m-0">Inversión Acumulada</p>
                <h3 class="text-secondary">{{ Totalizado | currency:'USD':'symbol':'1.0-0' }} <span class="fs-4 fw-medium">USD</span></h3>
                <!-- <h3 class="text-secondary">{{ inversionActual }} <span class="fs-4 fw-medium">USD</span></h3> -->
            </div>
        </div>

        <div class="pt-3 mt-0 col-12 contenedor-botones aparece-dos">
            <div class="col-11">
                <button class="btn btn-primary w-100 d-flex justify-content-between px-5 align-items-center" routerLink="/perfil03" routerLinkActive="active-link">Revisar la información de mi contrato<i class="fa-solid fa-angle-right"></i></button>
            </div>
        </div>

        <div class="col-11 mb-3 aparece-dos"> 
            <div class="contenedor-tarjetas">
                <div class="tarjetas mt-2 mb-5">
                    <table class="tarjeta tarjeta-light">
                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Rendimientos</p>
                            </td>
                            <td class="text-end">
                                <p>mensuales</p>
                                <!-- <p>{{ rendimiento | number:'1.2-2' }} <span class="fw-light">USD</span></p> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Aumentos de capital:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ aumentosCapital | number:'1.2-2' }} <span class="fw-light"></span></p>
                                <!-- <p>{{ aumentosCapital | number:'1.2-2' }} <span class="fw-light">USD</span></p> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Retiros:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ retiros | number:'1.2-2' }} <span class="fw-light"></span></p>
                                <!-- <p>{{ retiros | number:'1.2-2' }} <span class="fw-light">USD</span></p> -->
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Impuestos:</p>
                            </td>
                            <td class="text-end">
                                <p>USA</p>
                                <!-- <p>{{ impuestos | number:'1.2-2' }} <span class="fw-light">USD</span></p> -->
                            </td>
                        </tr>
                        <!-- <tr>
                            <td>
                                <p class="text-primary fw-regular fs-2">Balance:</p>
                            </td>
                            <td class="text-end">
                                <p>{{ balance | number:'1.2-2' }} <span class="fw-light">USD</span></p>
                            </td>
                        </tr> -->
                    </table>
                </div>
            </div>
        </div>

        <div class="pt-3 mt-0 col-12 contenedor-botones aparece-tres">
            <div class="col-11">
                <button class="btn btn-primary w-100 d-flex justify-content-between px-5 align-items-center" routerLink="/perfil-retiro01" routerLinkActive="active-link">Retirar rendimientos<i class="fa-solid fa-angle-right"></i></button>
            </div>
            <div class="col-11">
                <button class="btn btn-secondary w-100 d-flex justify-content-between px-5 align-items-center" routerLink="/perfil-aumento01" routerLinkActive="active-link">Aumentar mi inversión<i class="fa-solid fa-angle-right"></i></button>
            </div>
        </div>

        <div class="col-11 mb-3 aparece-tres">
            <div class="d-flex justify-content-between align-items-end">
              <span class="text-primary fs-1 fw-semibold">Movimientos</span>
              <span class="text-primary fs-3 fw-semibold">{{ mesAnio }}</span>
            </div>
            <div class="contenedor-tarjetas">
              <div class="tarjetas mt-2 mb-5">
                <table class="tarjeta tarjeta-movimientos">
                  <thead class="">
                    <tr class="">
                      <th class="w-25 text-start"><p><strong>Tipo</strong></p></th>
                      <th class="text-start" style="width: 40%;"><p><strong>Monto</strong></p></th>
                      <th class="text-end" style="width: 35%;"><p><strong>Fecha</strong></p></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let grupo of movimientosAgrupados | keyvalue">
                      <tr>
                        <td colspan="3" class="text-end text-primary month-header"><strong>{{ grupo.key }}</strong></td>
                      </tr>
                      <tr *ngFor="let movimiento of grupo.value" class="cont-rendimiento">
                        <td class="w-25">
                          <p class="text-capitalize text-ltl">{{ movimiento.tipo }}</p>
                        </td>
                        <td class="" style="width: 40%;">
                          <p class="text-start fw-semibold">${{ movimiento.monto | number:'1.2-2' }} USD</p>
                        </td>
                        <td class="" style="width: 35%;">
                          <p class="text-end text-ltl">{{ movimiento.fecha ? (movimiento.fecha | customDate) : '' }}</p>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
        </div>

        <div class="pt-3 mt-0 col-12 contenedor-botones aparece-tres">
            <div class="col-11">
                <button class="btn btn-info w-100 d-flex justify-content-between px-5 align-items-center" data-bs-toggle="modal" data-bs-target="#consultarPeriodoModal">Consultar otro periodo<i class="fa-solid fa-angle-right"></i></button>
            </div>
        </div>

        <div class="modal fade" id="consultarPeriodoModal" tabindex="-1" aria-labelledby="consultarPeriodoModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="consultarPeriodoModalLabel">Consultar otro periodo</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label for="mesSeleccionado" class="form-label">Seleccionar Periodo</label>
                        <select class="fs-6 me-2 form-select text-primary custom-select" id="mesSeleccionado" [(ngModel)]="mesSeleccionado">
                            <option class="my-5 py-5" disabled selected value="">--Selecciona una opción--</option>
                            <option class="my-5 py-5" *ngFor="let mes of mesesDisponibles" [value]="mes">{{ mes }}</option>
                        </select>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="consultarOtroPeriodo()">Consultar</button>
                    </div>
                </div>
            </div>
        </div>

        <div #resultados *ngIf="datosSeleccionados.length > 0" class="col-11 mt-3 aparece">
            <div class="d-flex justify-content-between align-items-end">
                <span class="text-primary fs-1 fw-semibold">Movimientos</span>
                <span class="text-primary fs-3 fw-semibold">{{ mesSeleccionado }}</span>
            </div>
            <div class="contenedor-tarjetas">
                <div class="tarjetas mt-2 mb-5">
                    <table class="tarjeta tarjeta-movimientos">
                        <thead>
                            <tr>
                                <th class="w-25 text-center"><p><strong>Tipo</strong></p></th>
                                <th class="text-center" style="width: 40%;"><p><strong>Monto</strong></p></th>
                                <th class="text-center" style="width: 35%;"><p><strong>Fecha</strong></p></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let movimiento of datosSeleccionados" class="cont-rendimiento">
                                <td class="w-25">
                                    <p class="text-capitalize text-ltl">{{ movimiento.tipo }}</p>
                                </td>
                                <td class="" style="width: 40%;">
                                    <p class="text-end fw-semibold">${{ movimiento.monto | number:'1.2-2' }} USD</p>
                                </td>
                                <td class="" style="width: 35%;">
                                    <p class="text-end text-ltl">{{ movimiento.fecha ? (movimiento.fecha | customDate) : '' }}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>